

.Trending{
    margin-top: 20px;
    margin-bottom: 30px;
    font-family: "Merriweather", serif;
}

.Trending h2{
    font-size: 40px;
    font-weight: 600;
    color: #1a1a1a;
}
