.ServicesNavbar {
    /* position: sticky; */
    width: 100%;
    background-color: #202734;
}

.merriweather-bold {
    font-family: "Merriweather", serif;
    font-weight: 700;
    font-style: normal;
}

.TopHeader {
    padding: 20px 25px 20px;
    font-family: "Merriweather", serif;
}


.TopHeaderFirst {
    font-family: "Merriweather", serif;
    color: #2bc0c1;
    font-size: 13px;
    font-weight: 700;
    cursor: pointer;
}

.TopHeaderIcon {
    font-family: "Merriweather", serif;
    color: #202734;
    font-size: 15px;
    font-weight: 700;
    margin-left: 10px;
}

.TopHeaderSecond {
    font-family: "Merriweather", serif;
    color: #202734;
    font-size: 13px;
    font-weight: 600;
    margin-left: 10px;
}

.ServicesFirstSection {
    height: 665px;
    background-position: 50%, 50%;
}



.ReasonsHireUs {
    padding: 40px;
    text-align: center;
    font-family: "Merriweather", serif;
    height: 100%;
}

.ReasonsHireUs h2 {
    color: #2bc0c1;
    font-size: 65px;
    font-weight: 500;
    line-height: 1.2;
    letter-spacing: 0px;
}

.ReasonsHireUs h6 {
    color: #505e6e;
    font-size: 20px;
    font-weight: 300;
    line-height: 1;
    letter-spacing: 0px;
}

.ReasonsHireUs h1 {
    color: #505e6e;
    font-size: 20px;
    font-weight: 300;
    line-height: 1;
    letter-spacing: 0px;
}

.HireUs {
    border: 1px solid #e0e0e8;
    padding: 20px;
    font-family: "Merriweather", serif;
    height: 100%;
}

.HireUs h2 {
    color: #2bc0c1;
    font-size: 62px;
    font-weight: 700;
    line-height: 1.4;
}

.HireUs h3 {
    color: #333e52;
    font-size: 30px;
    font-weight: 700;
    line-height: 1.4;
    letter-spacing: 0px;
}

.HireUs p {
    color: #505e6e;
    font-size: 18.4px;
    font-weight: 300;
    line-height: 1.5;
}

.ServicesSection {
    border: 1px solid #e0e0e8;
    padding: 20px;
    height: 100%;
    font-family: "Merriweather", serif;
}

.ServicesSection h3 {
    color: #333e52;
    font-size: 20px;
    margin-top: 20px;
    font-weight: 700;
    line-height: 1.5;
    letter-spacing: 0px;
}

/* .ServicesSection h3:hover {
    color: #2bc0c1;
    cursor: pointer;
    font-size: 30px;
    margin-top: 20px;
    font-weight: 700;
    line-height: 1;
    letter-spacing: 0px;
} */

.ServicesSection p {
    color: #505e6e;
    font-size: 16.8px;
    font-weight: 300;
    line-height: 1.5;
}

.ContactSection {
    font-family: "Merriweather", serif;
    padding: 30px 0px 10px;
    margin-top: 0px;
    margin-bottom: 0px;
}

.ContactSection h2 {
    font-size: 36px;
    font-weight: 400;
    line-height: 1.2;
    color: #333e52;
}


.ServiceContactSection {
    font-family: "Merriweather", serif;
    font-weight: 300;
    font-size: 20px;
    color: #505e6e;
    line-height: 1.6;
}

.ServiceContactSectionBottom {
    font-family: "Merriweather", serif;
    margin-top: 20px;
    font-weight: 300;
    font-size: 16px;
    color: #333e52;
    line-height: 1.5;
}

.CoomanImgService {
    height: 4.5rem;
}

.CommonTextService {
    text-align: start;
    margin-left: 30px;
    margin-right: 15px;
}

.CommonTextService h2 {
    color: #2bc0c1;
    font-size: 30px;
    font-weight: 700;
    line-height: 1.4;
    margin-bottom: 15px;
}

.CommonTextService p {
    color: #505e6e;
    font-size: 15.9px;
    font-weight: 300;
    line-height: 1.6;
}

.SecondContactSection {
    padding: 50px 0px 10px;
}

.SecondContactSection h2 {
    font-size: 36px;
    font-weight: 400;
    line-height: 1.2;
    color: #fff;
}


.SecondContactSectionBtm {
    font-family: "Merriweather", serif;
    font-weight: 300;
    font-size: 20px;
    color: #fff;
    line-height: 1.6;
}



@media screen and (max-width: 1050px) {

    .ServicesFirstSection {
        height: 565px;
        background-position: 30%, 50%;
    }

    .TopHeader {
        padding: 20px 20px 20px;
        font-family: "Merriweather", serif;
    }

    .CoomanImgService {
        height: 3.5rem;
    }

    .CommonTextService {
        text-align: start;
        margin-left: 30px;
        margin-right: 15px;
    }

    .CommonTextService h2 {
        color: #2bc0c1;
        font-size: 23px;
        font-weight: 700;
        line-height: 1.4;
        margin-bottom: 15px;
    }

    .CommonTextService p {
        color: #505e6e;
        font-size: 15.9px;
        font-weight: 300;
        line-height: 1.5;
    }

    .SecondContactSection h2 {
        font-size: 36px;
        font-weight: 400;
        line-height: 1.2;
        color: #fff;
    }


    .SecondContactSectionBtm {
        font-family: "Merriweather", serif;
        font-weight: 300;
        margin-top: 10px;
        font-size: 16px;
        color: #fff;
        line-height: 1.6;
    }
}

@media screen and (max-width: 980px) {

    .ServicesNavbar {
        position: fixed;
        width: 100%;
        background-color: #202734;
    }

    .TopHeader {
        padding: 20px 20px 20px;
        margin-top: 0px;
        font-family: "Merriweather", serif;
    }
}

@media screen and (max-width: 900px) {
    .ReasonsHireUs {
        padding: 40px;
        text-align: center;
        font-family: "Merriweather", serif;
    }

    .ReasonsHireUs h2 {
        color: #2bc0c1;
        font-size: 60px;
        font-weight: 500;
        line-height: 1.4;
        letter-spacing: 0px;
    }

    .HireUs {
        border: 1px solid #e0e0e8;
        padding: 20px;
        font-family: "Merriweather", serif;
    }

    .CoomanImgService {
        height: 3.5rem;
    }

    .CommonTextService {
        text-align: start;
        margin-left: 30px;
        margin-right: 15px;
    }

    .CommonTextService h2 {
        color: #2bc0c1;
        font-size: 23px;
        font-weight: 700;
        line-height: 1.4;
        margin-bottom: 15px;
    }

    .CommonTextService p {
        color: #505e6e;
        font-size: 15.9px;
        font-weight: 300;
        line-height: 1.5;
    }
}

@media screen and (max-width: 767px) {

    .ServicesFirstSection {
        height: 265px;
        background-position: 40%, 50%;
    }

    .ReasonsHireUs {
        padding: 10px;
        text-align: center;
        font-family: "Merriweather", serif;
    }

    .ReasonsHireUs h2 {
        color: #2bc0c1;
        font-size: 30px;
        font-weight: 500;
        line-height: 1.4;
        letter-spacing: 0px;
    }

    .ReasonsHireUs h1 {
        color: #505e6e;
        font-size: 15px;
        font-weight: 300;
        line-height: 1.3;
        letter-spacing: 0px;
    }

    .HireUs {
        border: 1px solid #e0e0e8;
        padding: 20px;
        font-family: "Merriweather", serif;
    }

    .CoomanImgService {
        height: 3.5rem;
    }

    .CommonTextService {
        text-align: start;
        margin-left: 22px;
    }

    .CommonTextService h2 {
        color: #2bc0c1;
        font-size: 23px;
        font-weight: 700;
        line-height: 1.4;
        margin-bottom: 15px;
    }

    .CommonTextService p {
        color: #505e6e;
        font-size: 15.9px;
        font-weight: 300;
        line-height: 1.5;
    }
}