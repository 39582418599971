.ArtificeMainBg {
    background-color: #042029;
    /* background-image: radial-gradient(at center center, #2EDAF1 0%, #053F48 76%); */
    /* opacity: 0.2; */
}


.orbitron {
    font-family: "Orbitron", sans-serif;
    font-style: normal;
}

.inter {
    font-family: "Inter", sans-serif;
    font-style: normal;
}

.IntelligenceArtificeMain {
    padding: 20px;
    font-family: "Orbitron", sans-serif;
    text-align: center;
}

.IntelligenceArtificeMain h2 {
    color: #fff;
    font-size: 40px;
    line-height: 1.3em;
    margin-bottom: 25px;
    font-weight: 600;
}

.IntelligenceArtificeMain span {
    color: #2EDAF1;
    font-size: 40px;
    line-height: 1.3em;
    margin-bottom: 25px;
    font-weight: 600;
}

.IntelligenceArtificeMain p {
    color: #FFFFFF70;
    font-size: 15px;
    line-height: 1.5em;
    font-weight: 300;
    font-family: "Inter", sans-serif;
    text-align: center;
}

.WhyChoseArtificeMain {
    padding: 30px;
    font-family: "Orbitron", sans-serif;
    text-align: center;
}

.WhyChoseArtificeMain h2 {
    color: #fff;
    font-size: 40px;
    line-height: 1.3em;
    margin-bottom: 15px;
    font-weight: 600;
}

.WhyChoseArtificeMain span {
    color: #2EDAF1;
    font-size: 40px;
    line-height: 1.3em;
    margin-bottom: 15px;
    font-weight: 600;
}

.WhyChoseArtificeMain p {
    width: 80%;
    color: #FFFFFF70;
    font-size: 16px;
    line-height: 1.5em;
    font-weight: 300;
    font-family: "Inter", sans-serif;
    text-align: center;
}

.ArtificeMainService {
    height: 100%;
    padding: 1.5em 1.5em 1.5em 1.5em;
    text-align: start;
    background-image: radial-gradient(at top right, #00E1FF24 0%, #01141D 50%);
    font-family: "Saira", sans-serif;
    border-radius: 10px;
}


.ArtificeMainService h2 {
    font-size: 23px;
    color: #fff;
    margin-top: 10px;
    font-weight: 300;
    margin-bottom: 15px;
}

.ArtificeMainService p {
    font-size: 14px;
    color: #FFFFFF70;
    font-weight: 300;
    margin-bottom: 0px;
}

.CommonIconArtificeMain i {
    background-color: transparent;
    padding: 14px;
    /* border: 1px solid #2EDAF1; */
    margin-bottom: 15px;
    text-align: start;
    color: #fff;
    font-size: 19px;
    border-radius: 100px;
    width: 50px;
    height: 50px;
}

/* .CommonIconArtificeMain img {
    background-color: #2EDAF1;
    padding: 10px;
    border: 1px solid #2EDAF1;
    margin-bottom: 15px;
    text-align: start;
    color: #01141D;
    border-radius: 100px;
    width: 50px;
    height: 50px;
} */

.CommonIconArtificeMain{
    background-color: transparent;
    border: 2px dashed #fff;
    text-align: center;
    margin-bottom: 20px;
    color: #fff;
    border-radius: 100px;
    width: 52px;
    height: 52px;
}

.CommonIconArtificeMain img {
    width: 40px;
    padding: 8px;
    text-align: center;
    height: 40px;
}

.BenefitsOfAI {
    height: 100%;
    padding: 1.5em 1.5em 1.5em 1.5em;
    /* text-align: center; */
    background-image: radial-gradient(at top right, #00E1FF24 0%, #01141D 50%);
    font-family: "Saira", sans-serif;
    border-radius: 10px;
}


.BenefitsOfAI h2 {
    font-size: 20px;
    color: #2EDAF1;
    margin-top: 10px;
    font-weight: 400;
    margin-bottom: 15px;
}

.BenefitsOfAI p {
    font-size: 14px;
    color: #FFFFFF70;
    font-weight: 300;
    margin-bottom: 0px;
}

.AIWork {
    font-family: "Orbitron", sans-serif;
    text-align: start;
}

.AIWork h2 {
    color: #fff;
    font-size: 21.9px;
    line-height: 1.5em;
    letter-spacing: 1px;
    margin-bottom: 25px;
    font-weight: 600;
}

.AIWork span {
    color: #2EDAF1;
    font-size: 21.9px;
    letter-spacing: 1px;
    line-height: 1.5em;
    margin-bottom: 25px;
    font-weight: 600;
}

.AIWork p {
    color: #FFFFFF70;
    font-size: 16px;
    line-height: 1.5em;
    font-weight: 300;
    font-family: "Inter", sans-serif;
    text-align: start;
}

.AITechnology {
    font-family: "Orbitron", sans-serif;
    text-align: start;
    margin-left: 20px;
}

.AITechnology h2 {
    color: #fff;
    font-size: 36px;
    line-height: 1.2em;
    letter-spacing: 1px;
    margin-bottom: 25px;
    font-weight: 600;
}

.AITechnology span {
    color: #2EDAF1;
    font-size: 36px;
    letter-spacing: 1px;
    line-height: 1.5em;
    margin-bottom: 25px;
    font-weight: 600;
}

.AITechnology p {
    color: #FFFFFF70;
    font-size: 16px;
    line-height: 1.5em;
    font-weight: 300;
    font-family: "Inter", sans-serif;
    text-align: start;
}

.AITechnology button {
    padding: 16px 32px;
    font-size: 13px;
    letter-spacing: 1px;
    border-radius: 50px;
    color: #fff;
    font-weight: 400;
    background-color: #FFFFFF00;
    fill: #fff;
    margin-top: 20px;
    border: 1px solid #2EDAF1;
    box-shadow: 0px 0px 24px 0px rgba(45.99999999999999, 217.9999999999999, 240.99999999999997, 0.49) inset;
}

.AITechnology button:hover {
    padding: 16px 32px;
    font-size: 13px;
    letter-spacing: 1px;
    border-radius: 50px;
    font-weight: 600;
    color: #01141D;
    background-color: #2EDAF1;
    fill: #fff;
    margin-top: 20px;
    border: 1px solid #2EDAF1;
    box-shadow: 0px 0px 24px 0px rgba(45.99999999999999, 217.9999999999999, 240.99999999999997, 0.49) inset;
}

.AIIndustries {
    margin-left: 30px;
}

.AIDevelopmentBenefits {
    margin-right: 30px;
}

.AITech {
    padding: 10px;
    border: 1px solid #2EDAF1;
    box-shadow: 0px 0px 24px 0px rgba(45.99999999999999, 217.9999999999999, 240.99999999999997, 0.49) inset;
    color: #fff;
}

.EmailMarketing{
    height: 400px;
}

@media screen and (max-width: 767px) {

    .IntelligenceArtificeMain {
        padding: 0px;
        font-family: "Orbitron", sans-serif;
        text-align: start;
    }

    .IntelligenceArtificeMain h2 {
        color: #fff;
        font-size: 30px;
        line-height: 1.3em;
        margin-bottom: 25px;
        font-weight: 600;
    }

    .IntelligenceArtificeMain span {
        color: #2EDAF1;
        font-size: 30px;
        line-height: 1.3em;
        margin-bottom: 25px;
        font-weight: 600;
    }

    .IntelligenceArtificeMain p {
        color: #FFFFFF70;
        font-size: 14px;
        line-height: 1.5em;
        font-weight: 300;
        font-family: "Inter", sans-serif;
        text-align: start;
    }

    .WhyChoseArtificeMain {
        padding: 10px;
        font-family: "Orbitron", sans-serif;
        text-align: start;
    }

    .WhyChoseArtificeMain p {
        width: 100%;
        color: #FFFFFF70;
        font-size: 16px;
        line-height: 1.5em;
        font-weight: 300;
        font-family: "Inter", sans-serif;
        text-align: start;
    }

    .WhyChoseArtificeMain h2 {
        color: #fff;
        font-size: 25px;
        line-height: 1.3em;
        margin-bottom: 0px;
        font-weight: 600;
    }

    .WhyChoseArtificeMain span {
        color: #2EDAF1;
        font-size: 25px;
        line-height: 1.3em;
        margin-bottom: 0px;
        font-weight: 600;
    }

    .AIWork h2 {
        color: #fff;
        font-size: 18px;
        line-height: 1.6em;
        letter-spacing: 1px;
        margin-bottom: 25px;
        font-weight: 600;
    }

    .AIWork span {
        color: #2EDAF1;
        font-size: 18px;
        letter-spacing: 1px;
        line-height: 1.6em;
        margin-bottom: 25px;
        font-weight: 600;
    }

    .AITechnology {
        font-family: "Orbitron", sans-serif;
        text-align: start;
        margin-left: 0px;
    }

    .AITechnology h2 {
        color: #fff;
        font-size: 25px;
        line-height: 1.5em;
        letter-spacing: 1px;
        margin-bottom: 25px;
        font-weight: 600;
    }

    .AITechnology span {
        color: #2EDAF1;
        font-size: 25px;
        letter-spacing: 1px;
        line-height: 1.5em;
        margin-bottom: 25px;
        font-weight: 600;
    }

    .AIDevelopmentBenefits {
        margin-right: 0px;
    }

    .AIIndustries {
        margin-left: 0px;
    }

    .EmailMarketing{
        height: 100%;
    }
}

@media screen and (max-width: 400px) {
    .ArtificeMainService {
        height: 100%;
        padding: 1em 1em 1em 1em;
        text-align: start;
        background-image: radial-gradient(at top right, #00E1FF24 0%, #01141D 50%);
        font-family: "Saira", sans-serif;
        border-radius: 10px;
    }

    .BenefitsOfAI {
        height: 100%;
        padding: 1em 1em 1em 1em;
        text-align: center;
        background-image: radial-gradient(at top right, #00E1FF24 0%, #01141D 50%);
        font-family: "Saira", sans-serif;
        border-radius: 10px;
    }

    .WhyChoseArtificeMain {
        padding: 0px;
        font-family: "Orbitron", sans-serif;
        text-align: start;
    }

    .AIDevelopmentBenefits {
        margin-right: 0px;
    }

    .AIIndustries {
        margin-left: 0px;
    }
}