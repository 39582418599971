.LogoSectionFooter {
    padding-top: 21px;
    font-family: "Merriweather", serif;
}


.LogoSectionFooter span {
    color: #2bc0c1;
    font-size: 40px;
    font-weight: 700;
    line-height: 1.2;
}

.LogoSectionFooter h3 {
    color: #fff;
    font-size: 35px;
    font-weight: 700;
    line-height: 1.2;
}

.LogoSectionFooter h6 {
    font-size: 23px;
    line-height: 1;
    letter-spacing: 1px;
    font-weight: 400;
    color: #fff;
}

.LogoSectionFooter h5 {
    font-size: 22px;
    line-height: 1;
    color: #fff;
    font-weight: 400;
}

.LogoSectionFooter h4 {
    font-size: 22px;
    line-height: 1;
    color: #2bc0c1;
    font-weight: 400;
}



.SocialIconFooter {
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 5px;
}

.SocialIconFooter i {
    background-color: #2BC0C1;
    width: 38px;
    height: 38px;
    font-size: 16px;
    color: #fff;
    margin-left: 20px;
    padding: 10px;
    border-radius: 25px;
}

.SocialIconFooter i:hover {
    background-color: #fff;
    width: 38px;
    height: 38px;
    font-size: 16px;
    color: #202734;
    margin-left: 20px;
    padding: 10px;
    border-radius: 25px;
}

.FooterBotomm {
    font-family: "Merriweather", serif;
    cursor: pointer;
    margin-top: 0px;
}

.FooterBotomm p {
    font-size: 15px;
    line-height: 1.6;
    font-weight: 300;
    color: #fff;
}

.FooterBotomm p:hover {
    font-size: 15px;
    line-height: 1.6;
    font-weight: 300;
    color: #2BC0C1;
}

.FooterBotomm span {
    color: #a6a6a6;
    font-size: 16px;
    cursor: default;
}


@media screen and (max-width: 767px) {
    .LogoSectionFooter h6 {
        font-size: 18px;
        line-height: 1.6;
        font-weight: 400;
    }

    .LogoSectionFooter h5 {
        font-size: 16.4px;
        color: #fff;
        line-height: 1.2;
        font-weight: 400;
    }


    .LogoSectionFooter h4 {
        font-size: 16px;
        color: #2bc0c1;
        font-weight: 400;
    }

    .SocialIconFooter {
        margin-top: 10px;
        margin-bottom: 10px;
        padding: 5px;
    }

    .SocialIconFooter i {
        background-color: #2BC0C1;
        width: 38px;
        height: 38px;
        font-size: 16px;
        color: #fff;
        margin-left: 20px;
        padding: 10px;
        border-radius: 25px;
    }

    .SocialIconFooter i:hover {
        background-color: #fff;
        width: 38px;
        height: 38px;
        font-size: 16px;
        color: #202734;
        margin-left: 20px;
        padding: 10px;
        border-radius: 25px;
    }

    .FooterBotomm {
        font-family: "Merriweather", serif;
        cursor: pointer;
        margin-top: 10px;
    }

}