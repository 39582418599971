


.ContactMainSection{
    padding: 20px;
    text-align: center;
    font-family: "Merriweather", serif;
}

.ContactMainSection h2{
    color: #2bc0c1;
    font-size: 62px;
    line-height: 1.6;
    margin-bottom: 10px;
    font-weight: 400;
}

.ContactMainSection p{
    color: #505e6e;
    text-decoration: none;
    font-size: 20px;
    line-height: 1.6;
    margin-bottom: 10px;
    font-weight: 300;
}

.ContactSectionContact {
    font-family: "Merriweather", serif;
    padding: 30px 0px 10px;
    margin-top: 0px;
    margin-bottom: 0px;
}

.ContactSectionContact h2 {
    font-size: 26px;
    font-weight: 400;
    text-align: center;
    margin-bottom: 15px;
    line-height: 1.2;
    color: #333e52;
}


.ContactHover{
    color: #505e6e;
    text-decoration: none;
    font-size: 20px;
    line-height: 1.6;
    margin-bottom: 20px;
    font-weight: 300;
}

.ContactHover:hover{
    color: #2bc0c1;
    text-decoration: none;
    font-size: 20px;
    line-height: 1.6;
    margin-bottom: 20px;
    font-weight: 300;
}

.ContactPageSection {
    padding: 40px;
    text-align: center;
    font-family: "Merriweather", serif;
}

.ContactPageSection h2 {
    color: #333e52;
    font-size: 34px;
    font-weight: 400;
    line-height: 1.4;
    letter-spacing: -1px;
}


@media screen and (max-width: 1050px) {
    .ContactSection {
        padding: 40px;
        text-align: center;
        font-family: "Merriweather", serif;
    }

    .ContactSection h2 {
        color: #333e52;
        font-size: 26px;
        font-weight: 400;
        line-height: 1.4;
        letter-spacing: -1px;
    }
}

@media screen and (max-width: 767px) {
    .ContactSection {
        padding: 20px;
        text-align: center;
        font-family: "Merriweather", serif;
    }

    .ContactSection h2 {
        color: #333e52;
        font-size: 24px;
        font-weight: 500;
        line-height: 1.4;
        letter-spacing: -1px;
    }

}