.MainImg {
    height: 800px;
}

.merriweather-bold {
    font-family: "Merriweather", serif;
    font-weight: 700;
    font-style: normal;
}

.HomeContentMain {
    position: relative;
}

.HomeContent {
    position: absolute;
    font-family: "Merriweather", serif;
    color: #fff;
    top: 35%;
    left: 20%;
    text-align: center;
}

.HomeContent h6 {
    text-align: center;
    font-size: 30px;
    line-height: 1;
    font-weight: 400;
    letter-spacing: 1px;
}

.HomeContent h2 {
    text-align: center;
    font-size: 130px;
    line-height: 1.1;
    font-weight: 500;
    letter-spacing: 3px;
}

.HomeContent h1 {
    text-align: center;
    font-size: 20px;
    line-height: 1.6;
    font-weight: 300;
    letter-spacing: 1px;
}

.HomeContent button {
    background-color: #2bc0c1;
    border: #2bc0c1;
    color: #fff;
    margin-top: 15px;
    padding: 15px 48px;
    font-weight: 600;
    border-radius: 30px;
}

.HomeContent button:hover {
    background-color: #202734;
    border: #202734;
    color: #fff;
    margin-top: 15px;
    padding: 15px 48px;
    font-weight: 600;
    border-radius: 30px;
}

.CommonIcon {
    font-size: 35px;
    color: #2bc0c1;
    text-align: center;
    font-weight: 600;
    margin-bottom: 15px;
}

.CommonText {
    text-align: start;
    margin-left: 20px;
    margin-right: 15px;
}

.CommonText h2 {
    color: #333e52;
    font-size: 33px;
    font-weight: 700;
    line-height: 1.4;
    margin-bottom: 5px;
}

.CommonText p {
    color: #333e52;
    font-size: 19.2px;
    font-weight: 300;
    line-height: 1.5;
    margin-bottom: 8px;
    color: #505e6e;
}

.MobileUse {
    height: 600px;
}


.ServiceSection {
    width: 63%;
    justify-content: center;
    align-items: center;
    padding: 50px 0;
    margin: 30px 0;
    text-align: center;
    font-family: "Merriweather", serif;
}

.ServiceSection h3 {
    font-size: 62px;
    text-align: center;
    color: #fff;
    line-height: 1.4;
    font-weight: 400;
    margin: 0 0 5px;
}


.ServiceSection p {
    font-size: 22px;
    text-align: center;
    color: #fff;
    line-height: 1.4;
    font-weight: 400;
    margin: 0 0 15px;
}

.FirstButton {
    background-color: #33A69A;
    border: none;
    color: #fff;
    border-radius: 25px;
    font-size: 15px;
    padding: 15px 50px;
    margin: 20px 0px;
    font-weight: 800;
}

.FirstButton:hover {
    background-color: #333;
    border: none;
    color: #fff;
    border-radius: 25px;
    font-size: 15px;
    padding: 15px 50px;
    margin: 20px 0px;
    font-weight: 800;
}

.SecondButton {
    background-color: transparent;
    border: 2px solid #fff;
    color: #fff;
    border-radius: 25px;
    letter-spacing: 1px;
    font-size: 15px;
    padding: 15px 50px;
    margin: 20px 0px;
    font-weight: 800;
}

.SecondButton:hover {
    background-color: #fff;
    border: 2px solid #fff;
    color: #33A69A;
    border-radius: 25px;
    letter-spacing: 1px;
    font-size: 15px;
    padding: 15px 50px;
    margin: 20px 0px;
    font-weight: 800;
}

.WhatMakes {
    margin-top: 20px;
}

.WhatMakes h5 {
    font-size: 32px;
    font-weight: 400;
    line-height: 1;
    color: #333e52;
}

.ConuntUpSection {
    font-family: "Merriweather", serif;
    padding: 30px;
    margin-top: 20px;
    margin-bottom: 40px;
}

.ConuntUpSection h2 {
    font-size: 52px;
    font-weight: 500;
    letter-spacing: 1px;
    line-height: 1.2;
    color: #fff;
}

.ConuntUpSection h5 {
    font-size: 32px;
    font-weight: 400;
    line-height: 1;
    color: #fff;
}

.TeamSection {
    font-family: "Merriweather", serif;
    padding: 30px;
    margin-top: 20px;
}

.TeamSection h2 {
    font-size: 62px;
    font-weight: 400;
    line-height: 1.2;
    color: #333e52;
}

.TeamSection h5 {
    font-size: 32px;
    font-weight: 400;
    line-height: 1;
    color: #333e52;
}

.TestimonialSection {
    font-family: "Merriweather", serif;
    padding: 30px;
    margin-top: 20px;
    margin-bottom: 50px;
}

.TestimonialSection h2 {
    font-size: 62px;
    font-weight: 400;
    line-height: 1;
    color: #fff;
}

.TestimonialSection h5 {
    font-size: 32px;
    font-weight: 400;
    line-height: 1;
    color: #fff;
}

.ProductText h2 {
    color: #333e52;
    font-size: 26px;
    line-height: 1;
    margin-top: 22px;
    font-weight: 700;
    text-align: center;
}

.ProductText h4 {
    color: #505e6e;
    font-size: 20px;
    line-height: 1.1;
    letter-spacing: 0.5px;
    font-weight: 300;
    text-align: center;
}

.SocialIcon {
    margin-top: 10px;
    margin-right: 20px;
    padding: 5px;
}

.SocialIcon i {
    background-color: #202734;
    width: 38px;
    height: 38px;
    font-size: 16px;
    color: #fff;
    margin-left: 20px;
    padding: 10px;
    border-radius: 25px;
}

.SocialIcon i:hover {
    background-color: #2BC0C1;
    width: 38px;
    height: 38px;
    font-size: 16px;
    color: #fff;
    margin-left: 20px;
    padding: 10px;
    border-radius: 25px;
}


.PortfolioSection {
    font-family: "Merriweather", serif;
    /* padding: 20px; */
    margin-top: 40px;
    padding-top: 50px;
    padding-bottom: 40px;
    margin-bottom: 30px;
}

.PortfolioSection h2 {
    font-size: 62px;
    font-weight: 400;
    line-height: 1.2;
    color: #333e52;
}

.PortfolioSection h5 {
    font-size: 35px;
    font-weight: 400;
    line-height: 1;
    color: #333e52;
}

.image {
    width: 100%;
    position: relative;
}

.image:hover {
    width: 100%;
    position: relative;
}

.image img {
    width: 100%;
    height: 235px;
    display: block;
}

.content {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    background: rgba(67, 71, 93, 0.8);
    display: flex;
    /* padding-left: 70px; */
    align-items: center;
    justify-content: center;
    flex-direction: column;
    opacity: 0;
    transition: 0.6s;
}

.content:hover {
    opacity: 1;
    cursor: pointer;
}

.content h5 {
    font-family: "Laila", serif;
    font-weight: 400;
    line-height: 30px;
    font-style: normal;
    text-align: center;
    font-size: 22px;
    /* padding-left: 26px; */
    letter-spacing: 2px;
    text-transform: uppercase;
    overflow: hidden;
    color: #fff;
    margin-bottom: 16px;
}

.content h5 {
    font-family: "Laila", serif;
    font-weight: 600;
    line-height: 25px;
    font-style: normal;
    text-align: center;
    font-size: 16px;
    letter-spacing: 2.5px;
    text-transform: uppercase;
    overflow: hidden;
    color: #fff;
}

.PricingTableMain {
    font-family: "Merriweather", serif;
    margin-top: 30px;
    border: 1px solid;
    border-radius: 4px;
    text-align: center;
    border-color: #e0e0e8;
}

.PricingTableMain h2 {
    font-weight: 700;
    color: #333e52;
    font-size: 32px;
    margin-top: 15px;
    margin-bottom: 10px;
    line-height: 1.2;
    letter-spacing: 1px;
}

.PricingTableMain span {
    font-weight: 700;
    color: #333e52;
    font-size: 24px;
    margin-right: 20px;
    line-height: 1.2;
}

.PricingTableMain p {
    font-weight: 800;
    color: #2bc0c1;
    font-size: 50px;
    line-height: 1.2;
    letter-spacing: 1px;
}

.PricingTableMain h6 {
    font-weight: 700;
    color: #505e6e;
    font-size: 18px;
}

.PricingTableMain h5 {
    font-weight: 300;
    padding: 10px;
    color: #505e6e;
    font-size: 16px;
    margin-top: 15px;
    margin-bottom: 10px;
    line-height: 1.5;
    letter-spacing: 0.6px;
}

.DividerCommon {
    width: 56%;
    margin: 0px;
    color: #434f53;
    margin-left: 80px;
}

.DividerPricingTableMainSecond {
    width: 56%;
    margin: 0px;
    color: #fcfcfc;
    margin-left: 80px;
}

.PricingTableMainSecond {
    font-family: "Merriweather", serif;
    margin-top: 0px;
    background-color: #2bc0c1;
    border: 1px solid;
    border-radius: 4px;
    padding-bottom: 30px;
    text-align: center;
    border-color: #e0e0e8;
}

.PricingTableMainSecond h2 {
    font-weight: 700;
    color: #fff;
    font-size: 32px;
    margin-top: 55px;
    margin-bottom: 10px;
    line-height: 1.2;
    letter-spacing: 1px;
}

.PricingTableMainSecond span {
    font-weight: 700;
    color: #fff;
    font-size: 24px;
    margin-right: 20px;
    line-height: 1.2;
}

.PricingTableMainSecond p {
    font-weight: 800;
    color: #fff;
    font-size: 50px;
    line-height: 1.2;
    letter-spacing: 1px;
}

.PricingTableMainSecond h6 {
    font-weight: 700;
    color: #fff;
    font-size: 18px;
}

.PricingTableMainSecond h5 {
    font-weight: 300;
    padding: 10px;
    color: #fff;
    font-size: 16px;
    margin-top: 15px;
    margin-bottom: 10px;
    line-height: 1.5;
    letter-spacing: 0.6px;
}

.PricingTableMainSecondButton {
    background-color: #fff;
    border: none;
    color: #111;
    border-radius: 25px;
    font-size: 15px;
    padding: 15px 50px;
    margin: 20px 0px;
    font-weight: 800;
}

.PricingTableMainSecondButton:hover {
    background-color: #333;
    border: none;
    color: #fff;
    border-radius: 25px;
    font-size: 15px;
    padding: 15px 50px;
    margin: 20px 0px;
    font-weight: 800;
}

.NewsEventsText {
    font-family: "Merriweather", serif;
    padding: 0 1px;
}

.NewsEventsText h2 {
    font-size: 26px;
    color: #333e52;
    margin-top: 20px;
    margin-bottom: 20px;
    font-weight: 700;
    line-height: 1.4;
}

.NewsEventsText h2:hover {
    font-size: 26px;
    color: #2bc0c1;
    margin-top: 20px;
    margin-bottom: 20px;
    font-weight: 700;
    line-height: 1.4;
}

.NewsEventsText p {
    font-size: 20px;
    color: #505e6e;
    margin-bottom: 20px;
    font-weight: 300;
    line-height: 1.4;
}

.NewsEventsText button {
    text-decoration: none;
    margin-left: -11px;
    font-weight: 900;
    color: #2bc0c1;
}

.NewsEventsText button:hover {
    text-decoration: none;
    margin-left: -11px;
    font-weight: 900;
    color: #333e52;
}

.whatsApp {
    position: fixed;
    right: 2.2rem;
    bottom: 15%;
    animation: zoom-in-zoom-out 1s ease infinite;
}

.whatsApp a i {
    font-size: 28px;
    border-radius: 50%;
    padding: 10px;
    color: #fff;
    background-color: #2bc0c1;
}

.CounterFirst {
    font-size: 40px;
    color: #fff;
    border: 3px solid #fff;
    border-radius: 50%;
    width: 140px;
    height: 130px;
}

.ServicesHome {
    text-align: center;
    margin-top: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
}

.ServicesHome h6 {
    font-size: 62px;
    color: #333e52;
}

.ServicesHome h4 {
    font-size: 30px;
    color: #333e52;
}

@media screen and (max-width: 1050px) {

    .HomeContent {
        position: relative;
        font-family: "Merriweather", serif;
        color: #fff;
        top: 15%;
        left: 0%;
        text-align: center;
    }

    .HomeContent h2 {
        text-align: center;
        font-size: 100px;
        line-height: 1.2;
        font-weight: 500;
        letter-spacing: 3px;
    }

    .HomeContent h6 {
        text-align: center;
        font-size: 25px;
        line-height: 1.2;
        font-weight: 400;
        letter-spacing: 1px;
    }

    .ServiceSection {
        width: 43%;
        justify-content: center;
        align-items: center;
        padding: 60px 0;
        margin: 70px 0;
        text-align: center;
        font-family: "Merriweather", serif;
    }

    .ServiceSection h3 {
        font-size: 62px;
        text-align: center;
        color: #fff;
        line-height: 1.4;
        font-weight: 400;
        margin: 0 0 5px;
    }

    .ServiceSection p {
        font-size: 25px;
        text-align: center;
        color: #fff;
        line-height: 1.3;
        font-weight: 400;
        margin: 0 0 5px;
    }

    .FirstButton {
        background-color: #33A69A;
        border: none;
        color: #fff;
        border-radius: 25px;
        font-size: 15px;
        padding: 15px 50px;
        margin: 10px 0px;
        font-weight: 800;
    }

    .FirstButton:hover {
        background-color: #333;
        border: none;
        color: #fff;
        border-radius: 25px;
        font-size: 15px;
        padding: 15px 50px;
        margin: 10px 0px;
        font-weight: 800;
    }

    .SecondButton {
        background-color: transparent;
        border: 2px solid #fff;
        color: #fff;
        border-radius: 25px;
        letter-spacing: 1px;
        font-size: 15px;
        padding: 10px 40px;
        margin: 10px 0px;
        font-weight: 800;
    }

    .SecondButton:hover {
        background-color: #fff;
        border: 2px solid #fff;
        color: #33A69A;
        border-radius: 25px;
        letter-spacing: 1px;
        font-size: 15px;
        padding: 10px 40px;
        margin: 10px 0px;
        font-weight: 800;
    }

    .CommonIcon {
        font-size: 25px;
        color: #2bc0c1;
        margin-bottom: 16px;
        text-align: center;
        font-weight: 600;
    }

    .CommonText {
        text-align: start;
        margin-left: 20px;
        margin-right: 15px;
    }

    .CommonText h2 {
        color: #333e52;
        font-size: 18px;
        font-weight: 700;
        line-height: 1.4;
        margin-bottom: 13px;
    }

    .CommonText p {
        color: #333e52;
        font-size: 14px;
        font-weight: 300;
        line-height: 1.5;
        margin-bottom: 8px;
        color: #505e6e;
    }

    .ConuntUpSection {
        font-family: "Merriweather", serif;
        padding: 10px;
        margin-bottom: 0px;
    }

    .ConuntUpSection h2 {
        font-size: 36px;
        font-weight: 400;
        line-height: 1.2;
        margin-top: 15px;
        margin-bottom: 15px;
        color: #fff;
    }

    .ConuntUpSection h5 {
        font-size: 22px;
        font-weight: 400;
        line-height: 1;
        margin-bottom: 20px;
        color: #fff;
    }

    .TeamSection {
        font-family: "Merriweather", serif;
        padding: 30px;
        margin-top: 0px;
        margin-bottom: 0px;
    }

    .TeamSection h2 {
        font-size: 36px;
        font-weight: 400;
        line-height: 1.2;
        color: #333e52;
    }

    .TeamSection h5 {
        font-size: 22px;
        font-weight: 400;
        line-height: 1;
        color: #333e52;
    }


    .TestimonialSection {
        font-family: "Merriweather", serif;
        padding: 30px;
        margin-top: 0px;
        margin-bottom: 0px;
    }

    .TestimonialSection h2 {
        font-size: 36px;
        font-weight: 400;
        line-height: 1.2;
        color: #fff;
    }

    .TestimonialSection h5 {
        font-size: 22px;
        font-weight: 400;
        line-height: 1;
        color: #fff;
    }

    .DividerCommon {
        width: 56%;
        margin: 0px;
        color: #434f53;
        margin-left: 70px;
    }

    .DividerPricingTableMainSecond {
        width: 56%;
        margin: 0px;
        color: #fcfcfc;
        margin-left: 70px;
    }

    .PricingTableMain {
        font-family: "Merriweather", serif;
        margin-top: 30px;
        border: 1px solid;
        border-radius: 4px;
        text-align: center;
        border-color: #e0e0e8;
    }

    .PricingTableMain h2 {
        font-weight: 700;
        color: #333e52;
        font-size: 25px;
        margin-top: 15px;
        margin-bottom: 10px;
        line-height: 1.2;
        letter-spacing: 1px;
    }

    .PricingTableMain span {
        font-weight: 700;
        color: #333e52;
        font-size: 18px;
        margin-right: 20px;
        line-height: 1.2;
    }

    .PricingTableMain p {
        font-weight: 800;
        color: #2bc0c1;
        font-size: 40px;
        line-height: 1.2;
        letter-spacing: 1px;
    }

    .PricingTableMain h6 {
        font-weight: 700;
        color: #505e6e;
        font-size: 16px;
    }

    .PricingTableMain h5 {
        font-weight: 300;
        padding: 10px;
        color: #505e6e;
        font-size: 14px;
        margin-top: 15px;
        margin-bottom: 10px;
        line-height: 1.5;
        letter-spacing: 1px;

    }


    .PricingTableMainSecond {
        font-family: "Merriweather", serif;
        margin-top: 0px;
        background-color: #2bc0c1;
        border: 1px solid;
        border-radius: 4px;
        padding-bottom: 30px;
        text-align: center;
        border-color: #e0e0e8;
    }

    .PricingTableMainSecond h2 {
        font-weight: 700;
        color: #fff;
        font-size: 25px;
        margin-top: 55px;
        margin-bottom: 10px;
        line-height: 1.2;
        letter-spacing: 1px;
    }

    .PricingTableMainSecond span {
        font-weight: 700;
        color: #fff;
        font-size: 18px;
        margin-right: 20px;
        line-height: 1.2;
    }

    .PricingTableMainSecond p {
        font-weight: 800;
        color: #fff;
        font-size: 40px;
        line-height: 1.2;
        letter-spacing: 1px;
    }

    .PricingTableMainSecond h6 {
        font-weight: 700;
        color: #fff;
        font-size: 16px;
    }

    .PricingTableMainSecond h5 {
        font-weight: 300;
        padding: 10px;
        color: #fff;
        font-size: 14px;
        margin-top: 15px;
        margin-bottom: 10px;
        line-height: 1.5;
        letter-spacing: 1px;
    }
}

@media screen and (max-width: 900px) {
    .MainImg {
        height: 650px;
    }

    .MobileUse {
        height: 100%;
    }

    .HomeContent {
        position: relative;
        font-family: "Merriweather", serif;
        color: #fff;
        padding: 30px;
        top: 8%;
        left: 0%;
        text-align: center;
    }

    .HomeContent h2 {
        text-align: center;
        font-size: 50px;
        line-height: 1.5;
        font-weight: 500;
        letter-spacing: 3px;
    }

    .HomeContent h6 {
        text-align: center;
        font-size: 20px;
        line-height: 1.5;
        font-weight: 400;
        letter-spacing: 1px;
    }

    .ConuntUpSection h2 {
        font-size: 26px;
        font-weight: 400;
        line-height: 1.2;
        color: #fff;
    }

    .ConuntUpSection h5 {
        font-size: 20px;
        font-weight: 400;
        line-height: 1;
        color: #fff;
    }

    .ServiceSection {
        width: 100%;
        justify-content: center;
        align-items: center;
        padding: 60px 0;
        margin: 70px 0;
        text-align: center;
        font-family: "Merriweather", serif;
    }

    .ServiceSection h3 {
        font-size: 50px;
        text-align: center;
        color: #fff;
        line-height: 1.4;
        font-weight: 400;
        margin: 0 0 5px;
    }

    .ServiceSection p {
        font-size: 20px;
        text-align: center;
        color: #fff;
        padding: 10px;
        line-height: 1.3;
        font-weight: 400;
        margin: 0 0 5px;
    }

    .WhatMakes {
        margin-top: 25px;
        padding-top: 20px;
        margin-bottom: 25px;
    }

    .WhatMakes h5 {
        font-size: 28px;
        font-weight: 600;
        line-height: 1;
        color: #333e52;
    }

    .TeamSection h2 {
        font-size: 30px;
        font-weight: 600;
        line-height: 1.2;
        color: #333e52;
    }

    .TeamSection h5 {
        font-size: 18.7px;
        font-weight: 400;
        line-height: 1.3;
        color: #333e52;
    }

    .TestimonialSection h2 {
        font-size: 32px;
        font-weight: 600;
        line-height: 1.2;
        color: #fff;
    }

    .TestimonialSection h5 {
        font-size: 18.7px;
        font-weight: 400;
        line-height: 1.3;
        color: #fff;
    }

    .DividerCommon {
        width: 56%;
        margin: 0px;
        color: #434f53;
        margin-left: 60px;
    }

    .DividerPricingTableMainSecond {
        width: 56%;
        margin: 0px;
        color: #fcfcfc;
        margin-left: 60px;
    }

    .PricingTableMain {
        font-family: "Merriweather", serif;
        margin-top: 30px;
        border: 1px solid;
        border-radius: 4px;
        text-align: center;
        border-color: #e0e0e8;
    }

    .PricingTableMain h2 {
        font-weight: 700;
        color: #333e52;
        font-size: 25px;
        margin-top: 15px;
        margin-bottom: 10px;
        line-height: 1.2;
        letter-spacing: 1px;
    }

    .PricingTableMain span {
        font-weight: 700;
        color: #333e52;
        font-size: 17px;
        margin-right: 20px;
        line-height: 1.2;
    }

    .PricingTableMain p {
        font-weight: 800;
        color: #2bc0c1;
        font-size: 38px;
        line-height: 1.2;
        letter-spacing: 1px;
    }

    .PricingTableMain h6 {
        font-weight: 700;
        color: #505e6e;
        font-size: 15px;
    }

    .PricingTableMain h5 {
        font-weight: 300;
        padding: 10px;
        color: #505e6e;
        font-size: 13px;
        margin-top: 15px;
        margin-bottom: 10px;
        line-height: 1.5;
        letter-spacing: 1px;
    }

    .PricingTableMainSecond {
        font-family: "Merriweather", serif;
        margin-top: 0px;
        background-color: #2bc0c1;
        border: 1px solid;
        border-radius: 4px;
        padding-bottom: 0px;
        text-align: center;
        border-color: #e0e0e8;
    }

    .PricingTableMainSecond h2 {
        font-weight: 700;
        color: #fff;
        font-size: 25px;
        margin-top: 55px;
        margin-bottom: 10px;
        line-height: 1.2;
        letter-spacing: 1px;
    }

    .PricingTableMainSecond span {
        font-weight: 700;
        color: #fff;
        font-size: 16px;
        margin-right: 20px;
        line-height: 1.2;
    }

    .PricingTableMainSecond p {
        font-weight: 800;
        color: #fff;
        font-size: 38px;
        line-height: 1.2;
        letter-spacing: 1px;
    }

    .PricingTableMainSecond h6 {
        font-weight: 700;
        color: #fff;
        font-size: 15px;
    }

    .PricingTableMainSecond h5 {
        font-weight: 300;
        padding: 10px;
        color: #fff;
        font-size: 13px;
        margin-top: 15px;
        margin-bottom: 10px;
        line-height: 1.5;
        letter-spacing: 1px;
    }

    .PortfolioSection {
        font-family: "Merriweather", serif;
        /* padding: 20px; */
        margin-top: 40px;
        padding-top: 30px;
        padding-bottom: 20px;
        margin-bottom: 20px;
    }

    .PortfolioSection h2 {
        font-size: 42px;
        font-weight: 400;
        line-height: 1.2;
        color: #333e52;
    }

    .PortfolioSection h5 {
        font-size: 17px;
        font-weight: 400;
        line-height: 1.2;
        color: #333e52;
    }
}

@media screen and (max-width: 767px) {

    .MainImg {
        height: 100%;
    }

    .HomeContent {
        position: relative;
        font-family: "Merriweather", serif;
        color: #fff;
        top: 5%;
        left: 0%;
        text-align: center;
    }

    .HomeContent h2 {
        text-align: center;
        font-size: 40px;
        line-height: 1.2;
        font-weight: 500;
        letter-spacing: 3px;
    }

    .HomeContent h6 {
        text-align: center;
        font-size: 22px;
        line-height: 1.2;
        font-weight: 400;
        letter-spacing: 1px;
    }

    .HomeContent h1 {
        text-align: center;
        font-size: 15px;
        line-height: 1.6;
        font-weight: 300;
        letter-spacing: 1px;
    }

    .DividerCommon {
        width: 70%;
        /* margin: 0px; */
        color: #434f53;
        margin-left: 60px;
    }

    .DividerPricingTableMainSecond {
        width: 70%;
        margin: 0px;
        color: #fcfcfc;
        margin-left: 60px;
    }

    .PricingTableMainSecond {
        font-family: "Merriweather", serif;
        margin-top: 30px;
        background-color: #2bc0c1;
        border: 1px solid;
        border-radius: 4px;
        padding-bottom: 30px;
        text-align: center;
        border-color: #e0e0e8;
    }

    .SocialIcon {
        margin-top: 10px;
        margin-right: 0px;
        padding: 5px;
    }

    .ServicesHome {
        text-align: center;
        margin-top: 20px;
        padding-top: 20px;
        padding-bottom: 40px;
    }

    .ServicesHome h6 {
        font-size: 62px;
        color: #333e52;
    }

    .ServicesHome h4 {
        font-size: 23.5px;
        color: #333e52;
    }
}

@media screen and (max-width: 500px) {

    .whatsApp {
        position: fixed;
        right: 0.7rem;
        bottom: 12%;
        animation: zoom-in-zoom-out 1s ease infinite;
    }

    .CounterFirst {
        font-size: 36px;
        color: #fff;
        border: 3px solid #fff;
        border-radius: 50%;
        width: 140px;
        height: 130px;
    }

}

@media screen and (max-width: 380px) {

    .DividerCommon {
        width: 80%;
        margin: 0px;
        color: #434f53;
        margin-left: 30px;
    }

    .DividerPricingTableMainSecond {
        width: 80%;
        margin: 0px;
        color: #fcfcfc;
        margin-left: 30px;
    }
}