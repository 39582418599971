.saira {
    font-family: "Saira", sans-serif;
    font-style: normal;
}


.AboutSectionHome h6 {
    color: #F4A492;
    font-size: 18px;
    font-family: "Laila", serif;
    margin-bottom: 20px;
}

.AboutSectionHome h3 {
    color: #fff;
    font-size: 2.5rem;
    font-weight: 700;
    font-family: "Playfair Display", serif;
    margin-bottom: 20px;
}

.AboutSectionHome h5 {
    color: #fff;
    font-size: 1rem;
    font-weight: 400;
    font-family: "Laila", serif;
    margin-bottom: 20px;
}

.AboutSectionHome h1 {
    color: #fff;
    font-size: 16.5px;
    font-weight: 600;
    font-family: "Laila", serif;
}

.AboutSectionHome p {
    color: #fff;
    font-size: 16.5px;
    font-weight: 400;
    font-family: "Laila", serif;
    margin-bottom: 25px;
}

.AboutSectionHome button {
    background: linear-gradient(90deg, rgb(215, 10, 132) 0%, rgb(81, 18, 127) 100%);
    border: 0;
    margin-top: 20px;
    padding: 13px 35px;
    font-weight: 600;
    font-size: 14px;
    color: rgb(255, 255, 255);
    border-radius: 100px;
}

.AboutSectionHome button:hover {
    background-color: #9ACCC9;
    margin-top: 20px;
    border: 0;
    padding: 13px 35px;
    font-weight: 600;
    font-size: 14px;
    color: #fff;
    border-radius: 100px;
}

.MainGradient {
    background: linear-gradient(135deg, #009FB2 0%, #4CBE1F 100%);
    padding: 2.6em 2em 2.6em 2em;
    font-family: "Saira", sans-serif;
}


.AISectionFirst {
    border-right: 2px solid #e0e0e8;
    padding: 20px;
    height: 100%;
}

.AISectionFirst p {
    font-size: 30px;
    font-weight: 300;
    line-height: 1.3em;
    color: #fff;
}

.AISectionSecond {
    padding: 20px;
    height: 100%;
}

.AISectionSecond p {
    font-size: 14px;
    margin-left: 20px;
    font-weight: 400;
    line-height: 1.6em;
    color: #fff;
    text-align: start;
}

.AboutSectionHome {
    margin-left: 20px;
}

.AboutSectionHome h6 {
    color: #009FB2;
    font-size: 16px;
    font-family: "Saira", sans-serif;
    margin-bottom: 20px;
}

.AboutSectionHome h3 {
    color: #fff;
    font-size: 46px;
    font-weight: 600;
    font-family: "Saira", sans-serif;
    margin-bottom: 20px;
}

.AboutSectionHome p {
    color: #fff;
    font-size: 15.5px;
    line-height: 25px;
    font-weight: 400;
    font-family: "Saira", sans-serif;
    margin-bottom: 25px;
}


.OurServices {
    font-family: "Saira", sans-serif;
}



.OurServices h2 {
    font-size: 45px;
    margin-top: 15px;
    margin-bottom: 10px;
    color: #fff;
    font-weight: 400;
}


.OurServices h6 {
    color: #009FB2;
    font-size: 16px;
    font-family: "Saira", sans-serif;
    margin-bottom: 20px;
    font-weight: 300;
}

.OurServices p {
    font-size: 16px;
    line-height: 28px;
    color: #fff;
    margin-bottom: 20px;
    font-weight: 400;
}

.OurServices button {
    background-color: #009FB2;
    border: none;
    color: #fff;
    margin-top: 20px;
    padding: 11px 30px;
    font-size: 16px;
    border-radius: 2px;
}

.OurServices button:hover {
    background-color: #4CBE1F;
    border: none;
    color: #fff;
    margin-top: 20px;
    padding: 11px 30px;
    font-size: 16px;
    border-radius: 2px;
}

.AboutService {
    height: 100%;
    padding: 2.5em 2.5em 2.5em 2.5em;
    text-align: center;
    background-color: #121520;
    font-family: "Saira", sans-serif;
    border-radius: 2px;
}

.AboutService h2 {
    font-size: 24px;
    color: #009FB2;
    font-weight: 300;
    margin-bottom: 15px;
}

.AboutService p {
    font-size: 15px;
    color: #fff;
    margin-bottom: 0px;
}

.CommonIconAbout {
    text-align: center;
    margin-bottom: 20px;
}

.CommonIconAbout i {
    background-color: transparent;
    padding: 16px;
    border: 1px solid #fff;
    color: #fff;
    font-size: 19px;
    border-radius: 100px;
    width: 55px;
    height: 55px;
}

.CommonIconAboutIcon {
    background-color: transparent;
    border: 2px dashed #fff;
    text-align: center;
    margin-bottom: 20px;
    color: #fff;
    border-radius: 100px;
    width: 60px;
    height: 60px;
}

.CommonIconAboutIcon img {
    width: 40px;
    padding: 8px;
    text-align: center;
    height: 40px;
}

.AIsectionBottom {
    padding-top: 20px;
    padding-bottom: 20px;
    text-align: center;
    font-family: "Saira", sans-serif;
    height: 100%;
}

.AIsectionBottom h2 {
    color: #2bc0c1;
    font-size: 42px;
    font-weight: 500;
    line-height: 1.5;
    letter-spacing: 0px;
}

.AIsectionBottom h6 {
    color: #505e6e;
    font-size: 22px;
    font-weight: 300;
    line-height: 1;
    letter-spacing: 0px;
}

.AboutServiceBottom {
    height: 100%;
    /* padding: 2.5em 2.5em 2.5em 2.5em; */
    text-align: center;
    font-family: "Saira", sans-serif;
    border-radius: 2px;
}

.AboutServiceBottom h2 {
    font-size: 18px;
    color: #009FB2;
    font-weight: 600;
    margin-bottom: 15px;
}

.AboutServiceBottom p {
    font-size: 15px;
    color: #111;
    margin-bottom: 0px;
}

.CommonIconAboutBottom i {
    color: #009FB2;
    font-size: 35px;
    border-radius: 100px;
    margin-bottom: 20px;
}

.CommonIconAboutBottom img {
    color: #009FB2;
    font-size: 35px;
    width: 45px;
    height: 45px;
    margin-bottom: 20px;
}


@media screen and (max-width: 767px) {

    .AISectionFirst {
        border-right: none;
        padding: 20px;
        height: 100%;
    }


    .AISectionSecond p {
        font-size: 14px;
        margin-left: 0px;
        font-weight: 400;
        line-height: 1.6em;
        color: #fff;
        text-align: start;
    }

    .AboutSectionHome {
        margin-left: 0px;
    }

    .MainGradient {
        background: linear-gradient(135deg, #009FB2 0%, #4CBE1F 100%);
        padding: .5em;
        font-family: "Saira", sans-serif;
    }

    .AboutSectionHome h1 {
        color: #fff;
        font-size: 16.5px;
        font-weight: 600;
        font-family: "Laila", serif;
    }


    .AIsectionBottom {
        padding: 10px;
        text-align: center;
        font-family: "Saira", sans-serif;
        height: 100%;
    }

    .AIsectionBottom h2 {
        color: #2bc0c1;
        font-size: 22px;
        font-weight: 500;
        line-height: 1.5;
        letter-spacing: 0px;
    }

    .AIsectionBottom h6 {
        color: #505e6e;
        font-size: 15px;
        font-weight: 300;
        line-height: 1.5;
        letter-spacing: 0px;
    }
}