.MainContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #ff7d7d;
}

.share-btn {
    position: relative;
    border: none;
    background: #fff;
    color: #ff7d7d;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    font-size: 30px;
    padding-top: 2.5px;
    padding-right: 10px;
    cursor: pointer;
    z-index: 2;
}


.share-options {
    position: absolute;
    bottom: 50%;
    left: 50%;
    width: auto;
    height: auto;
    transform-origin: bottom left;
    transform: scale(0);
    border-top-left-radius: 20px;
    border-bottom-right-radius: 20px;
    background: rgba(15, 15, 15, 0.5);
    color: #fff;
    padding: 20px;
    font-family: "roboto";
    transition: .5s;
    transition-delay: .5s;
}

.share-options.active{
    transform: scale(1);
    transition-delay: 0s;
}

.title{
    opacity: 0;
    transition: .5s;
    transition-delay: 0s;
    font-size: 20px;
    text-transform: capitalize;
    border-bottom: 1px solid #fff;
    width: fit-content;
    padding: 0 20px 3px 0;
}

.social-media{
    opacity: 0;
    transition: .5s;
    transition-delay: 0s;
    width: 250px;
    height: 120px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin: 10px 0;
}

.social-media-btn{
    border: none;
    width: 50px;
    height: 50px;
    color: #fff;
    border-radius: 50%;
    background: #000;
    line-height: 50px;
    font-size: 25px;
    cursor: pointer;
    margin: 0 5px;
    text-align: center;
}

.social-media-btn:nth-child(1){
    background: #FFA654;
}

.social-media-btn:nth-child(2){
    background: #25D366;
}

.social-media-btn:nth-child(3){
    background: #E4405F;
}

.social-media-btn:nth-child(4){
    background: #1DA1F2;
}

.social-media-btn:nth-child(5){
    background: #1877F2;
}

.social-media-btn:nth-child(6){
    background: #0A66c2;
}

.Link-container{
    opacity: 0;
    transition: .5s;
    transition-delay: 0s;
    width: 100%;
    position: relative;
    height: 40px;
    display: flex;
    align-items: center;
    border-radius: 40px;
    background-color: #fff;
    overflow: hidden;
    padding: 0 10px;
}

.Link{
    width: 80%;
    height: 100%;
    line-height: 40px;
    color: #000;
}

.copy-btn{
    position: absolute;
    right: 0;
    cursor: pointer;
    background: #000;
    color: #fff;
    border: none;
    height: 100%;
    width: 30%;
    text-transform: capitalize;
    font-size: 16px;
}

.share-options.active.title,
.share-options.active.social-media,
.share-options.active.Link-container{
    opacity: 0;
    transition: .5s;
    transition-delay: 0s;
}