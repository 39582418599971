.Main {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.top-btn {
    font-size: 1.4rem;
    width: 3rem;
    height: 3rem;
    color: #fff;
    background-color: #2bc0c1;
    border-radius: 50%;
    position: fixed;
    bottom: 2rem;
    right: 2rem;
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &--icon {
        animation: gototop 1.2s linear infinite alternate-reverse;
    }

    @keyframes gototop {
        0% {
            transform: translateY(-0.5rem);
        }

        100% {
            transform: translateY(1rem);
        }
    }
}

.top-btn:hover {
    font-size: 1.4rem;
    width: 3rem;
    height: 3rem;
    color: #fff;
    background-color: #000;
    border-radius: 50%;
    position: fixed;
    bottom: 2rem;
    right: 2rem;
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &--icon {
        animation: gototop 1.2s linear infinite alternate-reverse;
    }

    @keyframes gototop {
        0% {
            transform: translateY(-0.5rem);
        }

        100% {
            transform: translateY(1rem);
        }
    }

}

@media screen and (max-width: 500px) {
    .top-btn {
        font-size: 1.4rem;
        width: 3rem;
        height: 3rem;
        color: #fff;
        background-color: #2bc0c1;
        border-radius: 50%;
        position: fixed;
        bottom: 2rem;
        right: 0.7rem;
        z-index: 999;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    
        &--icon {
            animation: gototop 1.2s linear infinite alternate-reverse;
        }
    
        @keyframes gototop {
            0% {
                transform: translateY(-0.5rem);
            }
    
            100% {
                transform: translateY(1rem);
            }
        }
    
    }
}