.merriweather-bold {
    font-family: "Merriweather", serif;
    font-weight: 700;
    font-style: normal;
}

.laila-semibold {
    font-family: "Laila", serif;
    font-weight: 600;
    font-style: normal;
}

.AboutSecond {
    border: 1px solid #e0e0e8;
    padding: 55px 50px 55px 50px;
    font-family: "Merriweather", serif;
}

.AboutSecond h4 {
    font-size: 25px;
    color: #2bc0c1;
    line-height: 1.2;
    font-weight: 400;
    margin-bottom: 20px;
}

.AboutSecond h6 {
    font-size: 18.5px;
    color: #505e6e;
    line-height: 1.2;
    font-weight: 300;
}

.AboutSecond h1 {
    font-size: 18.5px;
    color: #505e6e;
    line-height: 1.2;
    font-weight: 300;
}

.AboutSecond p {
    font-size: 25px;
    color: #333e52;
    line-height: 1.3;
    font-weight: 400;
}

.AboutUsTeam h2 {
    color: #333e52;
    font-size: 26px;
    line-height: 1;
    margin-top: 22px;
    font-weight: 700;
    text-align: center;
}

.AboutUsTeam h4 {
    color: #505e6e;
    font-size: 19.5px;
    line-height: 1.5;
    font-weight: 300;
    margin-bottom: 20px;
    text-align: start;
}


.ABoutUsSection {
    width: 40%;
    justify-content: center;
    align-items: center;
    padding: 50px 0;
    margin: 50px 0;
    text-align: center;
    font-family: "Merriweather", serif;
}

.ABoutUsSection p {
    font-size: 30px;
    text-align: center;
    color: #fff;
    line-height: 1.5;
    font-weight: 400;
    margin: 0 0 15px;
}


.FAQ {
    padding: 40px;
    text-align: center;
    font-family: "Merriweather", serif;
    height: 100%;
}

.FAQ h2 {
    color: #2bc0c1;
    font-size: 30px;
    font-weight: 700;
    line-height: 1.2;
    letter-spacing: 0px;

}


.AboutContactSection {
    padding: 40px;
    text-align: center;
    font-family: "Merriweather", serif;
    height: 100%;
}

.AboutContactSection h2 {
    color: #2bc0c1;
    font-size: 30px;
    font-weight: 800;
    line-height: 1.2;
    letter-spacing: 0px;
}

.accordion-item {
    position: relative;
    border: 2px solid #fff;
    border-radius: 0px;
    font-family: "Merriweather", serif;
}

.accordion-button {
    background-color: #333e52;
    height: 48px;
    color: #fff;
    border-radius: 0px;
}

.accordion-item:first-of-type .accordion-button {
    border-top-left-radius: calc(0px - 0px);
    border-top-right-radius: calc(0px - 0px);
}

.accordion-button::after {
    flex-shrink: 0;
    width: 16px;
    height: 16px;
    content: "";
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' fill='black' class='bi bi-plus' viewBox='0 0 20 21'%3E%3Cpath d='M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4'/%3E%3C/svg%3E");
    transform: scale(1.7) !important;
    background-color: #fff;
    border-radius: 25px;
}

.accordion-button:focus {
    border-color: black;
    box-shadow: none;
}

.accordion-button:not(.collapsed) {
    background-color: #2bc0c1;
    color: #fff;
}

.accordion-button:not(.collapsed)::after {
    flex-shrink: 0;
    width: 16px;
    height: 16px;
    content: "";
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='10' fill='currentColor' class='bi bi-dash-lg' viewBox='0 0 20 20'%3E%3Cpath fill-rule='evenodd' d='M2 8a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11A.5.5 0 0 1 2 8'/%3E%3C/svg%3E");
    transform: scale(1.7) !important;
    background-color: #fff;
    border-radius: 25px;
}

.MainProgressSize {
    padding: 55px;
    margin-top: 40px;
}

.CommonProgressSize {
    padding: 14px;
}

.progress {
    display: flex;
    font-family: "Merriweather", serif;
    height: 4.5px;
    overflow: hidden;
    font-size: 0.75rem;
    background-color: #fff;
    border-radius: 0px;
}

.ProgressText {
    font-family: "Merriweather", serif;
    font-size: 20px;
    line-height: 2;
    color: #fff;
    font-weight: 700;
}

.ProgressPercentage {
    font-family: "Merriweather", serif;
    font-size: 40px;
    line-height: 1.6;
    color: #2bc0c1;
    font-weight: 700;
}

.progress-bar {
    height: 4.5px;
    background-color: #2bc0c1;
}



.AboutUsHireUs {
    border: 1px solid #e0e0e8;
    padding: 20px;
    font-family: "Merriweather", serif;
    height: 100%;
}

.AboutUsHireUs h2 {
    color: #2bc0c1;
    font-size: 62px;
    font-weight: 700;
    line-height: 1.4;
}

.AboutUsHireUs h3 {
    color: #333e52;
    font-size: 22px;
    font-weight: 700;
    line-height: 1.5;
    letter-spacing: 0px;
}

.AboutUsHireUs p {
    color: #505e6e;
    font-size: 18.4px;
    font-weight: 300;
    line-height: 1.5;
}

@media screen and (max-width: 767px) {

    .ABoutUsSection {
        width: 95%;
        justify-content: center;
        align-items: center;
        padding: 60px 0;
        margin: 70px 0;
        text-align: center;
        font-family: "Merriweather", serif;
    }

    .ABoutUsSection p {
        font-size: 22px;
        text-align: center;
        color: #fff;
        line-height: 1.3;
        font-weight: 400;
        margin: 0 0 15px;
    }

    .accordion-button {
        background-color: #333e52;
        height: 48px;
        font-size: 18px;
        padding: 50px 30px;
        color: #fff;
        border-radius: 0px;
    }

    .MainProgressSize {
        padding: 30px;
        margin-top: 10px;
    }

    .CommonProgressSize {
        padding: 15px;
    }

    .progress {
        display: flex;
        font-family: "Merriweather", serif;
        height: 3px;
        overflow: hidden;
        font-size: 0.75rem;
        background-color: #fff;
        border-radius: 0px;
    }

    .ProgressText {
        font-family: "Merriweather", serif;
        font-size: 15px;
        line-height: 2;
        color: #fff;
        font-weight: 700;
    }

    .ProgressPercentage {
        font-family: "Merriweather", serif;
        font-size: 22px;
        line-height: 1.6;
        color: #2bc0c1;
        font-weight: 700;
    }

    .progress-bar {
        width: 83%;
        height: 3px;
        background-color: #2bc0c1;
    }

    .progress-bar {
        height: 3px;
        background-color: #2bc0c1;
    }

    .AboutSecond {
        border: 1px solid #e0e0e8;
        padding: 30px 28px 30px 28px;
        font-family: "Merriweather", serif;
    }

    .AboutSecond h4 {
        font-size: 22px;
        color: #2bc0c1;
        line-height: 1.5;
        font-weight: 400;
    }

    .AboutSecond h6 {
        font-size: 18.5px;
        color: #505e6e;
        line-height: 1.5;
        font-weight: 300;
    }

    .AboutSecond h1 {
        font-size: 19px;
        color: #505e6e;
        line-height: 1.2;
        font-weight: 300;
    }

    .AboutSecond p {
        font-size: 22px;
        color: #333e52;
        line-height: 1.4;
        font-weight: 400;
    }

    
.AboutContactSection {
    padding: 20px;
    text-align: center;
    font-family: "Merriweather", serif;
    height: 100%;
}


    .AboutContactSection h2 {
        color: #2bc0c1;
        font-size: 22px;
        font-weight: 800;
        line-height: 1.2;
        letter-spacing: 0px;
    }
}