.PortfolioPage {
    font-family: "Merriweather", serif;
    margin-top: 40px;
    margin-bottom: 30px;
}

.PortfolioPage h2 {
    font-size: 62px;
    font-weight: 400;
    line-height: 1.1;
    color: #333e52;
}

.PortfolioPage h5 {
    font-size: 20px;
    font-weight: 400;
    line-height: 1.3;
    color: #505e6e;
}

@media screen and (max-width: 900px) {
    .PortfolioPage {
        font-family: "Merriweather", serif;
        margin-top: 20px;
        margin-bottom: 30px;
    }

    .PortfolioPage h2 {
        font-size: 42px;
        font-weight: 400;
        line-height: 1.1;
        color: #333e52;
    }

    .PortfolioPage h5 {
        font-size: 16px;
        font-weight: 400;
        line-height: 1.3;
        color: #505e6e;
    }
}

@media screen and (max-width: 767px) {
    .PortfolioPage {
        font-family: "Merriweather", serif;
        margin-top: 40px;
        margin-bottom: 30px;
    }

    .PortfolioPage h2 {
        font-size: 40px;
        font-weight: 400;
        line-height: 1.1;
        color: #333e52;
    }

    .PortfolioPage h5 {
        font-size: 16px;
        font-weight: 400;
        line-height: 1.3;
        color: #505e6e;
    }

}