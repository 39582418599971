.laila-semibold {
    font-family: "Laila", serif;
    font-weight: 600;
    font-style: normal;
}

.lato-black {
    font-family: "Lato", sans-serif;
    font-weight: 900;
    font-style: normal;
}

.merriweather-bold {
    font-family: "Merriweather", serif;
    font-weight: 700;
    font-style: normal;
}

.nav-item {
    border-top: 6px solid transparent;
}

.nav-item:hover {
    border-top: 6px solid #2bc0c1;
}

.navbar-nav {
    display: flex;
    width: 100%;
    padding: 0px 0px 0px 0px;
    text-transform: uppercase;
    font-family: "Laila", serif;
    font-weight: 800;
}

.navbar-nav .nav-link {
    color: #fff;
    padding: 40px;
    font-size: 11.3px;
}

.MainPaddingNav {
    padding-right: 15px;
}

.LogoSection {
    font-family: "Merriweather", serif;
}

.LogoSection span {
    color: #2bc0c1;
    font-size: 40px;
    font-weight: 700;
    line-height: 1.2;
}

.LogoSection h4 {
    color: #fff;
    font-size: 35px;
    font-weight: 700;
    line-height: 1.2;
}

.dropend {
    color: #fff;
    font-weight: 800;
    padding: 10px;
    margin-left: 5px;
}


.dropdown-item {
    color: #fff;
    padding: 10px;
    width: 180px;
}

.dropdown-item:hover {
    background-color: #202734;
    color: #11b6e4;
    width: 180px;
}


.dropdown-item.active {
    background-color: transparent;
}

.dropdown .dropdown-menu {
    display: none;
    width: 190px;
}

.dropdown:hover>.dropdown-menu,
.dropend:hover>.dropdown-menu {
    display: block;
    padding: 0px;
    font-size: .5rem;
    background-color: #202734;
    border-radius: 0px;
}

@media screen and (max-width: 1200px) {
    .dropend:hover>.dropdown-menu {
        position: absolute;
        top: 0;
        left: 100%;
    }

    .dropend .dropdown-toggle {
        margin-left: 0.5em;
    }

    .navbar-nav .nav-link {
        color: #fff;
        padding: 40px;
        font-size: .6411rem;
    }

    .MainPaddingNav {
        padding-right:6px;
    }
}


@media screen and (min-width: 1000px) {
    .dropend:hover>.dropdown-menu {
        position: absolute;
        top: 0;
        left: 100%;
    }

    .dropend .dropdown-toggle {
        margin-left: 0.5em;
    }


}


@media screen and (max-width: 990px) {
    .navbar>.container-fluid {
        display: flex;
        flex-direction: column;
        align-items: unset;
        justify-content: space-around;
        padding: 20px;
    }

    .navbar-nav .nav-link {
        color: #fff;
        padding: 7px 0px;
        font-size: .8411rem;
    }

    .nav-item {
        border-top: none;
    }

    .nav-item:hover {
        border-top: none;
    }

    .navbar-toggler {
        margin-bottom: 0px;
        margin-top: 0px;
        border-radius: 0px;
        line-height: 1.1;
        /* border: 1px solid transparent; */
    }

    /* .navbar-toggler{
        margin-bottom: 20px;
        margin-top: 10px;
        border-radius: 0px;
        line-height: 1.1;
    } */

    .navbar-toggler i {
        background-color: #202734;
        font-size: 1.25rem;
        border: #202734;
        padding: 0.70rem 0.66rem;
        color: #fff;
    }

    .navbar-toggler:focus {
        text-decoration: none;
        box-shadow: none;
    }

    .LogoSection h4 {
        color: #fff;
        font-size: 35px;
        font-weight: 700;
        margin-left: 10px;
        line-height: 1.2;
    }
}